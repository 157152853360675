
import {
  defineComponent,
  PropType,
  computed,
  ref,
  toRefs,
  SetupContext,
  watch,
  onMounted,
  reactive,
} from 'vue';
import validation from '@/modules/validation';
export default defineComponent({
  inheritAttrs: false,
  props: {
    modelValue: [String, Number],
    type: {
      type: String as PropType<
        | 'text'
        | 'password'
        | 'search'
        | 'email'
        | 'date'
        | 'currency'
        | 'select'
      >,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: 'h-12',
    },
    defaultValue: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Object,
      default: null,
    },
    form: {
      type: Object,
      default: null,
    },
    name: {
      type: String,
      default: '',
    },
    iconName: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  setup: (props, { emit }: SetupContext) => {
    const { type, form, data, name, modelValue }: any = toRefs(props);

    const { validateField } = validation();

    const prop = reactive({
      inputValue: '',
      focused: false,
    });

    const togglePassword = ref<boolean>(false);

    const input = ref<any>(null);

    const isPassword = computed(() => type?.value === 'password');

    const isSelect = computed(() => type?.value === 'select');

    const field = computed(() =>
      data.value
        ? data.value
        : form.value && name.value && form.value?.data[name.value]
        ? form.value?.data[name.value]
        : null,
    );

    const errors = computed(() => field.value?.errors);

    const textColor = computed(() => (errors.value ? 'text-red-500' : ''));

    const isActive = computed(
      () => !!prop.focused || !!prop.inputValue?.toString()?.length,
    );

    const isFocused = computed(() => !!prop.focused);

    const change = (value: string) => {
      prop.inputValue = value;
      emit('update:modelValue', prop.inputValue);
      validateData();
    };

    const firstError = computed(() => {
      if (errors.value) {
        const key = Object.keys(errors.value)[0];
        switch (key) {
          case 'same':
            return 'The passwords do not match';
          default:
            return errors.value[key];
        }
      } else {
        return null;
      }
    });

    const setBlur = (): boolean => (prop.focused = false);

    const setFocus = (): boolean => (prop.focused = true);

    const showPassword = (): boolean =>
      (togglePassword.value = !togglePassword.value);

    const validateData = (): void => {
      if (name.value && field.value) {
        validateField(name.value, field.value, input.value, form.value);
      }
    };

    watch(
      () => modelValue.value,
      (value) => {
        prop.inputValue = value;
        change(value);
      },
    );

    watch(
      () => prop.inputValue,
      (value) => {
        change(value);
      },
    );

    onMounted(() => {
      prop.inputValue = modelValue.value;
    });

    return {
      isPassword,
      change,
      showPassword,
      togglePassword,
      input,
      textColor,
      firstError,
      isSelect,
      setFocus,
      setBlur,
      isActive,
      isFocused,
      ...toRefs(prop),
    };
  },
});
